var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainScroll" },
    [
      _vm.$acl.get[0] === "user"
        ? _c(
            "div",
            { staticClass: "messageSubscribe" },
            [
              _c(
                "b-alert",
                { attrs: { show: "" } },
                [
                  _vm._v(
                    "Para poder ver la lista negra personalizada de los clics evitados a tu cuenta de anuncios, "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "text-decoration-none font-weight-bold",
                      attrs: { to: "/payment" }
                    },
                    [_vm._v("mejora al modo protección")]
                  ),
                  _vm._v(".")
                ],
                1
              )
            ],
            1
          )
        : _c("b-table", {
            staticClass: "ana-table",
            attrs: {
              "primary-key": "gCampaign",
              "tbody-transition-props": _vm.transProps,
              selectable: "",
              "select-mode": "single",
              fields: _vm.fieldsAnalytics,
              items: _vm.items,
              filter: _vm.filter,
              filterIncludedFields: _vm.filterOn,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "sort-direction": _vm.sortDirection,
              striped: "",
              bordered: "",
              hover: "",
              "sticky-header": "70vh"
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              },
              filtered: _vm.onFiltered
            },
            scopedSlots: _vm._u([
              {
                key: "cell(blockIP)",
                fn: function(data) {
                  return [
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(data.value))
                    ])
                  ]
                }
              },
              {
                key: "cell(blockDate)",
                fn: function(data) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm
                            ._f("moment")(data.value)
                            .format("HH:mm:ss DD-MM-YYYY")
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "table-caption",
                fn: function() {
                  return [
                    _c(
                      "b-col",
                      { staticClass: "my-1", attrs: { md: "6" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              id: "showMoreBlackList",
                              variant: "primary",
                              hidden: _vm.firstElement ? null : true
                            },
                            on: { click: _vm.chargeItems }
                          },
                          [_vm._v("Mostrar más")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }