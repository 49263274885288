<template>
  <div class='mainScroll'>
    <div v-if="$acl.get[0]==='user'" class="messageSubscribe">
      <b-alert show>Para poder ver la lista negra personalizada de los clics evitados a tu cuenta de anuncios, <router-link to="/payment" class="text-decoration-none font-weight-bold">mejora al modo protección</router-link>.</b-alert>
    </div>
    <b-table v-else class="ana-table" primary-key="gCampaign" :tbody-transition-props="transProps" selectable select-mode="single" :fields="fieldsAnalytics" :items="items" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" striped bordered hover sticky-header="70vh">
      <template v-slot:cell(blockIP)="data">
        <span class="text-danger">{{ data.value }}</span>
      </template>
      <template v-slot:cell(blockDate)="data">
        <span>{{ data.value | moment().format("HH:mm:ss DD-MM-YYYY") }}</span>
      </template>
      <template v-slot:table-caption>
        <b-col md="6" class="my-1">
          <b-button id="showMoreBlackList" variant="primary" @click="chargeItems" :hidden="firstElement ? null : true">Mostrar más</b-button>
        </b-col>
      </template>
    </b-table>
  </div>
</template>

<script>
import Amplify from 'aws-amplify'
import { EventBus } from '@/event-bus'
import utils from '@/api/utils.js'

export default {
  data() {
    return {
      transProps: {
        name: 'flip-list'
      },
      firstElement: null,
      perPage: 25,
      sortBy: 'blockDate',
      rowSel: false,
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      ipSelectec: '',
      filterOn: [],
      items: [],
      fieldsAnalytics: [
        {
          key: 'blockIP',
          label: 'Dirección IP',
          class: 'table-ip-colum',
        },
        {
          key: 'nClicks',
          label: 'Clics',
          class: 'table-nclicks-colum',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'blockDate',
          label: 'Fecha de Bloqueo',
          class: 'table-blockdate-colum',
          sortable: true,
          sortDirection: 'desc'
        },
      ],
    }
  },
  methods: {
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
      this.chargeItems()
      // FIN Get user
    },
    transformDataFormat(ctx) {
      let dataItems = { items: [] }
      for (let i = 0; i < ctx.length; i++) {
        dataItems.items.push({
          blockIP: ctx[i].ipAddress,
          nClicks: ctx[i].nClicks,
          blockDate: ctx[i].timestamp,
          gCampaign: ctx[i].gCampaign
        });
      }
      return dataItems.items
    },
    chargeItems() {
      if (this.$acl.get[0] !== 'user') {
        this.$vs.loading()
        Amplify.Auth.currentSession().then(apiSession => {
          let paramsBlack = {
            numItems: this.perPage,
            firstItem: this.firstElement,
            gAccount: this.$store.state.user.mainAccount,
            userId: this.$store.state.user.id
          }
          this.$axios
            .post(`/blacklist/${this.$store.state.user.mainAccount}`, paramsBlack, {
              headers: {
                Authorization: "Bearer " + apiSession.idToken.jwtToken
              }
            })
            .then(response => {
              const results = response.data
              const dataitems = this.transformDataFormat(results.items)
              this.items = this.items.concat(dataitems)
              this.firstElement = results.lastIndex
              this.$vs.loading.close()
            })
          return null
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rowSel = true
        this.ipSelectec = items[0].blockIP
      } else {
        this.rowSel = false
        this.ipSelectec = ''
      }
    },
  },
  mounted() {
    EventBus.$on('update-blacklist', () => {
      this.items.length = 0
      this.firstElement = null
      this.chargeItems()
    })
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
  },
}
</script>
<style>
table.ana-table .flip-list-move {
  transition: transform 1s;
}
.ana-table th {
  font-size: 1.3rem;
  text-align: center;
}
.ana-table td {
  vertical-align: middle;
  text-align: center;
}
.table-ip-colum {
  width: 33%;
  font-size: 1.2rem;
  font-weight: bold;
}
.table-nclicks-colum {
  width: 33%;
}
.table-blockdate-colum {
  width: 33%;
}
.filterpos {
  justify-content: flex-end;
}
.filterinput {
  font-size: 1.2rem;
}
.firstinput {
  padding: 15px 0px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  text-align: center;
}
.firstinput:focus,
.secondinput:focus {
  outline: none !important;
  box-shadow: none;
}
.secondinput {
  padding: 0px 0px 15px 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.buttonclear {
  padding: 0.375rem 0.75rem !important;
}
.messageSubscribe {
  margin: 2% auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>